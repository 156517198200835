import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useGetLatestOriginationProcessIdQuery } from '@harmoney/redux';
import { Spinner } from '@harmoney/ui-design-system';

import { withAuth } from '../components/hoc';
import { useAuth } from '../hooks/use-auth';
import { useGetUserQuery } from '../redux/services';
import { useAppSelector } from '../redux/store';

const IndexPage = () => {
  const { login, isLoading, isAuthenticated } = useAuth();
  const token = useAppSelector((state) => state.accessToken.value);
  const userId = useAppSelector((state) => state.userId.value);
  const { push, query, isReady } = useRouter();
  const { data: userData, isLoading: isUserDataLoading } = useGetUserQuery(null, {
    skip: !isAuthenticated || !token || !userId,
  });
  const { data: processData, isLoading: isProcessDataLoading } = useGetLatestOriginationProcessIdQuery(null, {
    skip: !isAuthenticated || !token || !userId,
  });

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      login();
    }
  }, [isAuthenticated, isLoading, login]);

  useEffect(() => {
    if (isAuthenticated && !isUserDataLoading && !isProcessDataLoading && userId && isReady) {
      if (userData && !userData?.preferredName && processData && processData?.processId) {
        push(`journey?journeyId=${processData?.processId}${query?.pqId ? `&pqId=${query?.pqId}` : ''}`);
      } else {
        push('/dashboard');
      }
    }
  }, [userData, isUserDataLoading, isAuthenticated, userId, isProcessDataLoading, push, processData, isReady, query]);

  return <Spinner size="large" />;
};

export default withAuth(IndexPage);
